exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-fitness-tsx": () => import("./../../../src/pages/fitness.tsx" /* webpackChunkName: "component---src-pages-fitness-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-templates-post-list-tsx": () => import("./../../../src/templates/post-list.tsx" /* webpackChunkName: "component---src-templates-post-list-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tag-list-tsx": () => import("./../../../src/templates/tag-list.tsx" /* webpackChunkName: "component---src-templates-tag-list-tsx" */)
}

